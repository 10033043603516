
// export default AddRedeemingWays;
import { CircularProgress, FormControl, InputAdornment, MenuItem, RadioGroup } from "@mui/material";
import { useFormik } from "formik";
// import localforage from "localforage";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CopyText from "../../../../components/CopyText";
import CustomButton from "../../../../components/CustomButton";
import CustomDropDown from "../../../../components/CustomDropDown";
import CustomRadio from "../../../../components/CustomRadio";
import CustomSelect from "../../../../components/CustomSelect";
import CustomCheckBox from "../../../../components/CustomCheckBox";
import CustomTextArea from "../../../../components/CustomTextArea";
import CustomTextField from "../../../../components/CustomTextField";
import { BrandContext } from "../../../../context/Brand.context";
import { LayoutContext } from "../../../../context/Layout.context";
import { Brand, enums } from "../../../../enums";
import { PngIcons, SvgIcons } from "../../../../icons";
import DateRangePicker from 'react-bootstrap-daterangepicker'
import { PointOfSaleRounded } from "@mui/icons-material";
import IconButton from '@mui/material/IconButton';
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import localforage, * as localForage from "localforage";
import {
  CampaignService,
  DealService,
  InventoryService,
  RedeemingWayService,
} from "../../../../services";
import { showToaster } from "../../../../utils/utils";
import { Options, utils } from '../../../../utils'
import DeleteModal from "../../../../components/DeleteModal";
import CustomsModal from "../../../../components/CustomModal";
import CustomModal from '../../../../components/CustomModal';
import { ColorSchemeCode } from "../../../../enums/ColorScheme";
import CustomTextFieldValue from "../../../../components/CustomTextFieldValue";
import { Card, CardActionArea, CardContent, CardMedia, InputLabel, Select, Typography } from "@material-ui/core";
import { ImageModalV2 } from "../../../../components/ImageModalV2/ImageModalV2";
import LogoUploader from "../../../../components/LogoUploader";
import { InventoryVld } from "../../../../validation";
import { AgencyContext } from "../../../../context/Agency.context";
import axios from "axios";

function AddRedeemingWays() {
  const history = useHistory();
  const layout = useContext(LayoutContext);
  const brand = useContext(BrandContext);
  const agency = useContext(AgencyContext);
// 
  const [imageModal, setImageModal] = useState({
    logo: { open: false, imageEvent: "", return: "" },
    cover: { open: false, imageEvent: "", return: "" },
  });
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [index1, setIndex] = useState();
  
  const initState1 = {
    _id: "",
    brandLogo: "",
    brandLogoFile: "",
    name: "",
    nameq: "",
    price: "0",
    description: "",
    edit: false,
  };

  const formik1 = useFormik({
    initialValues: { ...initState1 },
    // onSubmit: handleSubmit,
    validationSchema: InventoryVld.Create,
    // isInitialValid   : inventoryData && inventoryData.edit,
    // isInitialValid: false,
    // enableReinitialize: true,
  });

  const [state1, setstate1] = useState({
    apiError: "",
    loader: false,
    deleteLoader: false,
    deleteModal: false,
    mainLoader: true,
    deleteItem: "",
    deleteIndex: "",
    result: [],
  });
  const [data, setData] = useState(state1.result);
  const [reload, setreload] = useState(true);

  const handleSubmit1 = async () => {
    setstate1({ ...state1, loader: true });

    if (formik1.values.brandLogoFile == "" && formik1.values.brandLogo == "") {
      utils.showToaster({
        position: "top-right",
        title: "Missing Reward Logo",
        message: "Reward Logo is required",
        severity: "error",
      });
    } else {
      const user = await localForage.getItem("user");
      // const desiredPath = `${user._id}/${formik1.values.productName}/${formik.values.brandLogoFile?.name.replace(/[\s~`!@#$%^&*(){}\[\];:"'<,.>?\/\\|_+=-]/g, '')}`;
      const desiredPath = `${user._id}/${formik.values.name}/${utils.getRandomString(8)}`

      let logo;
      if (formik1.values.brandLogoFile && !formik1.errors.brandLogoFile) {
        logo = await utils.uploadImage({
          file: formik1.values.brandLogoFile,
          desiredPath,
        });
        if (logo.error)
          return setstate1({
            ...state1,
            loader: false,
            apiError: logo.error.message,
          });
      }

      let payload = {
        name: formik1.values.name.toLowerCase(),
        description: formik1.values.description,
        image: logo && logo.response.data,
        price: formik1.values.price,
        agencyId: agency._id,
        brandId: brand._id,
      };

      let payload2 = {
        name: formik1.values.name,
        description: formik1.values.description,
        image: logo && logo.response.data,
        agencyId: agency._id,
        brandId: brand._id,
        discountType: Brand.DealDiscountType.AMOUNT_TOTAL_PRODUCT,
        discountValue: 0,
        discountedPrice: formik1.values.price,
        totalPrice: formik1.values.price,
      };

      // console.log('====================================');
      // console.log(payload);
      // console.log(payload2);
      // console.log('====================================');

      if (!agency._id) delete payload2.agencyId;

      if (!formik1.values.edit) {
        payload.prefix = utils.getRandomString(3);
        const { response, error } = await InventoryService.Create({
          toaster: true,
          payload,
        });
        if (error) setstate1({ ...state1, loader: false, apiError: error.error });
        else {
          let inventory = await localforage.getItem("inventory");
          if (inventory == null) inventory = [];
          inventory.push(response.data);
          localforage.setItem("inventory", inventory);

          payload2.items = [response.data._id];

          const dealres = await DealService.Create({
            payload: { ...payload2 },
          });
          if (dealres.error)
            setstate1({
              ...state1,
              loader: false,
              apiError: dealres.error.error,
            });
          else {
            let deal = await localForage.getItem("deal");
            if (deal === undefined || deal === null) deal = [];
            deal.push(dealres.response.data);
            localForage.setItem("deal", deal);
            setData(deal)
            state.result = deal;
            setstate1({ ...state1 });
            setModal2(false);
            setModal1(false);
            setTimeout(() => {
              formik1.setValues({ ...initState1 });
            }, 2000);
          }
        }
      } else {
        if (formik1.values?.name1 === formik1.values.name) delete payload2.name;
        console.log('YesZoop');
        const { response, error } = await DealService.Update({
          toaster: true,
          payload: { ...payload2, _id: formik1.values._id },
        });
        if (error) setstate1({ ...state1, loader: false, apiError: error.error });
        else {
          let deal = await localForage.getItem("deal");
          deal.map((element, idx) => {
            if (element._id === formik1.values._id) deal[idx] = response.data;
          });
          setData(deal)
          localForage.setItem("deal", deal);
          state.result = deal;
          setstate1({ ...state1 });
          setModal2(false);
          setModal1(false);
          setTimeout(() => {
            formik1.setValues({ ...initState1 });
          }, 2000);
        }
      }
    }
    setstate1({ ...state1, loader: false });
    setreload(!reload)
  };

  const handleDelete1 = async (e) => {
    setDeleteLoader1(true);
    const payload = {
      _id: formik1.values._id,
      delete: true,
    };

    // console.log('payload', payload);


    const { error, response } = await DealService.Update({
      toaster: true,
      payload,
    });

    // console.log('response', response, error);
    if (error) setstate1({ ...state1, loader: false, apiError: error.error });

    let inventory = await localForage.getItem("deal");
    let temp = [];
    inventory.map((element, idx) => {
      if (element._id !== formik1.values._id) temp.push(element);
    });
    localForage.setItem("deal", temp);
    setData(temp)
    state1.result = temp;
    setstate1({ ...state1 });
    formik1.setValues({ ...initState1 });
    setDeleteLoader1(false);
    setDeleteModal1(false);
    setModal2(false);
    setreload(!reload)
  };
// 

  const [tags, settags] = useState([
    {type: 'Visits', number: 0, name: 'tag'}
  ])
  

  const [loader, setLoader] = useState(false);
  // const [fulfil, setFulfil] = useState(false);
  const [filterAudience, setFilterAudience] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);

  const [name, setName] = useState(
    history.location.state.name || "Reward " + (filteredOptions.length + 1)
  );
  const [editStatus, setEditStatus] = useState(true);
    
  const [deleteModal1,setDeleteModal1]=useState(false);
  const [deleteLoader1, setDeleteLoader1]=useState(false);
  const [deleteModal,setDeleteModal]=useState(false);
  const [deleteLoader, setdeleteLoader]=useState(false);
  const [state, setState] = useState({
      loader   : false,
      apiError : ''
  })

  useEffect(() => {
    if(history.location.state.tag) settags(history.location.state.tag)
    if (name === "" && !editStatus) {
      showToaster({
        position: "bottom-right",
        title: "Title Error",
        message: "Title cannot be empty",
        severity: "error",
      });
      setName("Reward " + (filteredOptions.length + 1));
    }
  }, [editStatus, filteredOptions]);

  useEffect(async () => {
    if (history.location.state.rewardDealId)
      setFilterAudience([history.location.state.rewardDealId]);

    let deal = await localforage.getItem("deal");
    if (deal === null || deal.length === 0) {
      const query = { delete: false, brandId: brand._id };
      const { error, response } = await DealService.Get({ query });
      if (response){
        deal = response.data;
      }
    }

    let temp = [];
    console.log('deal',deal);
    if (deal && deal.length > 0){
      for (const value of deal) {
        temp.push({
          label: value?.name,
          value: value?._id,
          image: <img src={value?.image} width="20px" heigth="20px" />,
          data: value,
        });
      }
    }
    else{
      temp.push({
        label: deal?.name,
        value: deal?._id,
        image: <img src={deal?.image} width="20px" heigth="20px" />,
        data: deal,
      });
    }

    setFilteredOptions(temp);

    console.log("BRNDDD", brand)
  }, [reload]);


  const [promo, setpromo] = useState([])
  const [selectedpromo, setselectedpromo] = useState()
  async function onLoad() {
    if(brand?.integrations?.cloudwaitress?.length>0&&brand?.integrations?.cloudwaitress[0]?.id){
      const config = {
        method: 'GET',
        url: "https://api.orderingdashboard.com/v1/restaurants/"+brand.integrations.cloudwaitress[0].id,
        // url: "https://api.orderingdashboard.com/v1/restaurants/"+formState.selected+"/integration/walletly",
        headers: {
          Authorization: brand.integrations.cloudwaitress[0].auth,  // API key
          ClientId: '4f8c1385-a157-4a73-8d7f-d3364f8fd651'       // Client ID
        }
      };
      const response = await axios(config);
      console.log('response.data.cw',response.data);
      if(response?.data?.promos?.length>0){
        setpromo(response.data.promos)
      }
    }
  }

  useEffect(async() => {
    if(history.location.state.cwCode){
      if(brand?.integrations?.cloudwaitress?.length>0&&brand?.integrations?.cloudwaitress[0]?.id){
        const config = {
          method: 'GET',
          url: "https://api.orderingdashboard.com/v1/restaurants/"+brand.integrations.cloudwaitress[0].id,
          // url: "https://api.orderingdashboard.com/v1/restaurants/"+formState.selected+"/integration/walletly",
          headers: {
            Authorization: brand.integrations.cloudwaitress[0].auth,  // API key
            ClientId: '4f8c1385-a157-4a73-8d7f-d3364f8fd651'       // Client ID
          }
        };
        const response = await axios(config);
        console.log('response.data.cw',response.data);
        if(response?.data?.promos?.length>0){
          for (const element of response?.data?.promos) {
            if(element.code === history.location.state.cwCode) setselectedpromo(element)
          }
          // setpromo(response.data.promos)
        }
      }
    }
  }, [history.location.state])
  

  useEffect(() => { onLoad() }, [])
  

  console.log('history.location.state ', history.location.state)
  console.log(' dddddd', history.location)

  const initState = {
    cwCode: history.location.state.cwCode || "",
    PointCost: history.location.state.PointCost || 0,
    active: history.location.state.active===true?true:history.location.state.active===false?false:'',
    PointCostTo: history.location.state.PointCostTo || 0,
    tagName: history.location.state.tagName || "",
    PointCostBy: history.location.state.PointCostBy || 0,
    offerPoints: history.location.state.offerPoints || 0,
    numberofDays: history.location.state.numberofDays || 0,
    PointCostType: history.location.state.PointCostType || "signup",
    CustomerGets: history.location.state.CustomerGets || 0,
    Description: history.location.state.Description ? history.location.state.Description
      :
      history.location.state.redeemingWayType ===
        enums.RedeemingWays.BIRTHDAY ? 'Set up a loyalty programs that rewards customer on their Birthday’s too.'
        :
        history.location.state.redeemingWayType ===
          enums.RedeemingWays.REFERRAL ?
          'Referrals are automated, sent via text, and proven to boost retention and conversion for businesses of any size' : '',
    DiscountType:
      history.location.state.DiscountType ||
      enums.RedeemingDiscountType.NoDiscount,
    DiscountTypeValue: history.location.state.DiscountTypeValue || 0,
    BonusPoints: history.location.state.BonusPoints || "",
    DateType: history.location.state.DateType || "",
    calenderValue      : history.location.state.calenderValue ||'1',
    dateRange          : history.location.state.dateRange ||'',
    onetime: history.location.state.onetime || false,
    widget: history.location.state.widget || false,
    fulfil : false
  };
  console.log('history.location.state.BonusPoints', history.location.state);

  useEffect(async() => {
    // const query = { brandId: brand._id, delete: false, redeemingWayType: "product"}
    // let redeemingWays = await RedeemingWayService.GetQueriedEarningWays({ query })
    // console.log('redeemingWays1',redeemingWays)
    // // redeemingWays.reverse()
    // console.log('redeemingWays2',redeemingWays.response.data.reverse())
    
    const matchingOption = filteredOptions.filter(option => filterAudience.includes(option.value));
    console.log('matchingOption',matchingOption)
  }, [])
  

  const handleSubmit = async () => {
    setLoader(true);

    // const campaign = await CampaignService.GetQueriedCampaign({
    //   query: { brandId: brand._id, campaignType: 5 },
    // });
    let campaign = await localforage.getItem('campaign');
    console.log('campaign',campaign);
    if ((!campaign) || campaign.length === 0) {
      console.log('campaign in',campaign);
      const ccampaign = await CampaignService.GetPublicCampaign({ query: { brandId: brand._id, delete: false, campaignType: 5 } })
      if (ccampaign.response?.data?.length > 0){
        campaign = ccampaign.response.data
        localForage.setItem("campaign", ccampaign.response.data);
      }
    }
    let payload = {
      brandId: brand._id,
      // campaignId: campaign.response.data[0]._id,
      campaignId: campaign[0]?._id || campaign._id,
      name: layout.elements.editTitle || "new",
      redeemingWayType: history.location.state.redeemingWayType,
    };

    if(history.location.state.redeemingWayType === enums.RedeemingWays.BIRTHDAY){
      if(formik.values.active === true){
          try {
              const url = 'https://rest.gohighlevel.com/v1/custom-values/';
              const config = {
                  headers: {
                      Authorization: 'Bearer '+brand.integrations.ghl.apiKey // Replace 'your_token_here' with your actual token
                  }
              };
              const brandBDData = {
                  name: "brandBD",
                  value: formik.values.PointCost.toString()||"0"
              };
              const get = await axios.get(url, config);
              console.log('Response:', get.data);
              if(get.data?.customValues?.length>0)
              for (const element of get.data.customValues) {
                  if(element.name === "brandBD"){
                      const brandBD = await axios.put(url+element.id, brandBDData, config);
                      console.log('Response:', brandBD.data);
                  }
              }
          } catch (error) {
          console.error('Error:', error.response ? error.response.data : error.message);
          }
      }
      else{
          try {
              const url = 'https://rest.gohighlevel.com/v1/custom-values/';
              const config = {
                  headers: {
                      Authorization: 'Bearer '+brand.integrations.ghl.apiKey // Replace 'your_token_here' with your actual token
                  }
              };
              const brandBDData = {
                  name: "brandBD",
                  value: ""
              };
              const get = await axios.get(url, config);
              console.log('Response:', get.data);
              if(get.data?.customValues?.length>0)
              for (const element of get.data.customValues) {
                  if(element.name === "brandBD"){
                      const brandBD = await axios.put(url+element.id, brandBDData, config);
                      console.log('Response:', brandBD.data);
                  }
              }
          } catch (error) {
          console.error('Error:', error.response ? error.response.data : error.message);
          }
      }
    }
    
    if(history.location.state.redeemingWayType === enums.RedeemingWays.INACTIVITY){
      if(formik.values.active === true){
          try {
              const url = 'https://rest.gohighlevel.com/v1/custom-values/';
              const config = {
                  headers: {
                      Authorization: 'Bearer '+brand.integrations.ghl.apiKey // Replace 'your_token_here' with your actual token
                  }
              };
              const brandInactiveDaysData = {
                  name: "brandInactiveDays",
                  value: formik.values.numberofDays.toString()||"0"
              };
              const brandInactivePtsData = {
                  name: "brandInactivePts",
                  value: formik.values.offerPoints.toString()||"0"
              };
              const get = await axios.get(url, config);
              console.log('Response:', get.data);
              if(get.data?.customValues?.length>0)
              for (const element of get.data.customValues) {
                  if(element.name === "brandInactiveDays"){
                      const brandInactiveDays = await axios.put(url+element.id, brandInactiveDaysData, config);
                      console.log('Response:', brandInactiveDays.data);
                  }
                  if(element.name === "brandInactivePts"){
                      const brandInactivePts = await axios.put(url+element.id, brandInactivePtsData, config);
                      console.log('Response:', brandInactivePts.data);
                  }
              }
          } catch (error) {
          console.error('Error:', error.response ? error.response.data : error.message);
          }
        }
        else{
          try {
              const url = 'https://rest.gohighlevel.com/v1/custom-values/';
              const config = {
                  headers: {
                      Authorization: 'Bearer '+brand.integrations.ghl.apiKey // Replace 'your_token_here' with your actual token
                  }
              };
              const brandInactiveDaysData = {
                  name: "brandInactiveDays",
                  value: ""
              };
              const brandInactivePtsData = {
                  name: "brandInactivePts",
                  value: ""
              };
              const get = await axios.get(url, config);
              console.log('Response:', get.data);
              if(get.data?.customValues?.length>0)
              for (const element of get.data.customValues) {
                  if(element.name === "brandInactiveDays"){
                      const brandInactiveDays = await axios.put(url+element.id, brandInactiveDaysData, config);
                      console.log('Response:', brandInactiveDays.data);
                  }
                  if(element.name === "brandInactivePts"){
                      const brandInactivePts = await axios.put(url+element.id, brandInactivePtsData, config);
                      console.log('Response:', brandInactivePts.data);
                  }
              }
          } catch (error) {
          console.error('Error:', error.response ? error.response.data : error.message);
          }
      }
    }
    
    if(history.location.state.redeemingWayType === enums.RedeemingWays.REFERRAL){
      if(formik.values.active === true){
          try {
              const url = 'https://rest.gohighlevel.com/v1/custom-values/';
              const config = {
                  headers: {
                      Authorization: 'Bearer '+brand.integrations.ghl.apiKey // Replace 'your_token_here' with your actual token
                  }
              };
              const brandRefTypeData = {
                  name: "brandRefType",
                  value: formik.values.PointCostType.toString()||"0"
              };
              const brandRefSenderData = {
                  name: "brandRefSender",
                  value: formik.values.PointCostBy.toString()||"0"
              };
              const brandReferralData = {
                  name: "brandReferral",
                  value: formik.values.PointCostTo.toString()||"0"
              };
              const get = await axios.get(url, config);
              console.log('Response:', get.data);
              if(get.data?.customValues?.length>0)
              for (const element of get.data.customValues) {
                  if(element.name === "brandRefType"){
                      const brandRefType = await axios.put(url+element.id, brandRefTypeData, config);
                      console.log('Response:', brandRefType.data);
                  }
                  if(element.name === "brandRefSender"){
                      const brandRefSender = await axios.put(url+element.id, brandRefSenderData, config);
                      console.log('Response:', brandRefSender.data);
                  }
                  if(element.name === "brandReferral"){
                      const brandReferral = await axios.put(url+element.id, brandReferralData, config);
                      console.log('Response:', brandReferral.data);
                  }
              }
          } catch (error) {
          console.error('Error:', error.response ? error.response.data : error.message);
          }
        }
        else{
          try {
              const url = 'https://rest.gohighlevel.com/v1/custom-values/';
              const config = {
                  headers: {
                      Authorization: 'Bearer '+brand.integrations.ghl.apiKey // Replace 'your_token_here' with your actual token
                  }
              };
              const brandRefTypeData = {
                  name: "brandRefType",
                  value: ""
              };
              const brandRefSenderData = {
                  name: "brandRefSender",
                  value: ""
              };
              const brandReferralData = {
                  name: "brandReferral",
                  value: ""
              };
              const get = await axios.get(url, config);
              console.log('Response:', get.data);
              if(get.data?.customValues?.length>0)
              for (const element of get.data.customValues) {
                  if(element.name === "brandRefType"){
                      const brandRefType = await axios.put(url+element.id, brandRefTypeData, config);
                      console.log('Response:', brandRefType.data);
                  }
                  if(element.name === "brandRefSender"){
                      const brandRefSender = await axios.put(url+element.id, brandRefSenderData, config);
                      console.log('Response:', brandRefSender.data);
                  }
                  if(element.name === "brandReferral"){
                      const brandReferral = await axios.put(url+element.id, brandReferralData, config);
                      console.log('Response:', brandReferral.data);
                  }
              }
          } catch (error) {
          console.error('Error:', error.response ? error.response.data : error.message);
          }
      }
    }
    
    if(history.location.state.redeemingWayType === enums.RedeemingWays.PRODUCT){
      const query = { brandId: brand._id, delete: false, redeemingWayType: "product"}
      let redeemingWays = await RedeemingWayService.GetQueriedEarningWays({ query })
      console.log('redeemingWays1',redeemingWays)
      // redeemingWays.reverse()
      // console.log('redeemingWays2',redeemingWays.response.data.reverse())

      console.log('1',history.location.state._id);
      if(redeemingWays?.response?.data?.length>0)
        for (const [index, value] of redeemingWays.response.data.entries()) {
        console.log('2',index, value);
    
        if(index === 0 && history.location.state._id === value._id){
          if(formik.values.active === true){
            try {
                const url = 'https://rest.gohighlevel.com/v1/custom-values/';
                const config = {
                    headers: {
                        Authorization: 'Bearer '+brand.integrations.ghl.apiKey // Replace 'your_token_here' with your actual token
                    }
                };
                const matchingOption = filteredOptions.filter(option => filterAudience.includes(option.value));
                console.log('matchingOption',matchingOption)

                const brandRewardName1Data = {
                    name: "brandRewardName1",
                    value: matchingOption[0].data.name||"0"
                };
                const brandRewardImage1Data = {
                    name: "brandRewardImage1",
                    value: matchingOption[0].data.image||"0"
                };
                const brandRewardDesc1Data = {
                    name: "brandRewardDesc1",
                    value: matchingOption[0].data.description||"0"
                };
                const brandRewardPts1Data = {
                    name: "brandRewardPts1",
                    value: formik.values.PointCost.toString()||"0"
                };
                const brandRewardCode1Data = {
                    name: "brandRewardCode1",
                    value: selectedpromo.code||""
                };
                const get = await axios.get(url, config);
                console.log('Response:', get.data);
                if(get.data?.customValues?.length>0)
                for (const element of get.data.customValues) {
                    if(element.name === "brandRewardCode1"){
                        const brandRewardCode1 = await axios.put(url+element.id, brandRewardCode1Data, config);
                        console.log('Response:', brandRewardCode1.data);
                    }
                    if(element.name === "brandRewardName1"){
                        const brandRewardName1 = await axios.put(url+element.id, brandRewardName1Data, config);
                        console.log('Response:', brandRewardName1.data);
                    }
                    if(element.name === "brandRewardImage1"){
                        const brandRewardImage1 = await axios.put(url+element.id, brandRewardImage1Data, config);
                        console.log('Response:', brandRewardImage1.data);
                    }
                    if(element.name === "brandRewardDesc1"){
                        const brandRewardDesc1 = await axios.put(url+element.id, brandRewardDesc1Data, config);
                        console.log('Response:', brandRewardDesc1.data);
                    }
                    if(element.name === "brandRewardPts1"){
                        const brandRewardPts1 = await axios.put(url+element.id, brandRewardPts1Data, config);
                        console.log('Response:', brandRewardPts1.data);
                    }
                }
            } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            }
          }
          else{
            try {
                const url = 'https://rest.gohighlevel.com/v1/custom-values/';
                const config = {
                    headers: {
                        Authorization: 'Bearer '+brand.integrations.ghl.apiKey // Replace 'your_token_here' with your actual token
                    }
                };
                const matchingOption = filteredOptions.filter(option => filterAudience.includes(option.value));
                console.log('matchingOption',matchingOption)

                const brandRewardName1Data = {
                    name: "brandRewardName1",
                    value: ""
                };
                const brandRewardImage1Data = {
                    name: "brandRewardImage1",
                    value: ""
                };
                const brandRewardDesc1Data = {
                    name: "brandRewardDesc1",
                    value: ""
                };
                const brandRewardPts1Data = {
                    name: "brandRewardPts1",
                    value: ""
                };
                const brandRewardCode1Data = {
                    name: "brandRewardCode1",
                    value: ""
                };
                const get = await axios.get(url, config);
                console.log('Response:', get.data);
                if(get.data?.customValues?.length>0)
                for (const element of get.data.customValues) {
                    if(element.name === "brandRewardName1"){
                        const brandRewardName1 = await axios.put(url+element.id, brandRewardName1Data, config);
                        console.log('Response:', brandRewardName1.data);
                    }
                    if(element.name === "brandRewardCode1"){
                        const brandRewardCode1 = await axios.put(url+element.id, brandRewardCode1Data, config);
                        console.log('Response:', brandRewardCode1.data);
                    }
                    if(element.name === "brandRewardImage1"){
                        const brandRewardImage1 = await axios.put(url+element.id, brandRewardImage1Data, config);
                        console.log('Response:', brandRewardImage1.data);
                    }
                    if(element.name === "brandRewardDesc1"){
                        const brandRewardDesc1 = await axios.put(url+element.id, brandRewardDesc1Data, config);
                        console.log('Response:', brandRewardDesc1.data);
                    }
                    if(element.name === "brandRewardPts1"){
                        const brandRewardPts1 = await axios.put(url+element.id, brandRewardPts1Data, config);
                        console.log('Response:', brandRewardPts1.data);
                    }
                }
            } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            }
          }
        }
        if(index === 1 && history.location.state._id === value._id){
          if(formik.values.active === true){
            try {
                const url = 'https://rest.gohighlevel.com/v1/custom-values/';
                const config = {
                    headers: {
                        Authorization: 'Bearer '+brand.integrations.ghl.apiKey // Replace 'your_token_here' with your actual token
                    }
                };
                const matchingOption = filteredOptions.filter(option => filterAudience.includes(option.value));
                console.log('matchingOption',matchingOption)
  
                const brandRewardName1Data = {
                    name: "brandRewardName2",
                    value: matchingOption[0].data.name||"0"
                };
                const brandRewardImage1Data = {
                    name: "brandRewardImage2",
                    value: matchingOption[0].data.image||"0"
                };
                const brandRewardDesc1Data = {
                    name: "brandRewardDesc2",
                    value: matchingOption[0].data.description||"0"
                };
                const brandRewardCode2Data = {
                    name: "brandRewardCode2",
                    value: selectedpromo.code||""
                };
                const brandRewardPts1Data = {
                  name: "brandRewardPts2",
                  value: formik.values.PointCost.toString()||"0"
              };
                const get = await axios.get(url, config);
                console.log('Response:', get.data);
                if(get.data?.customValues?.length>0)
                for (const element of get.data.customValues) {
                    if(element.name === "brandRewardCode2"){
                        const brandRewardCode2 = await axios.put(url+element.id, brandRewardCode2Data, config);
                        console.log('Response:', brandRewardCode2.data);
                    }
                    if(element.name === "brandRewardName2"){
                        const brandRewardName1 = await axios.put(url+element.id, brandRewardName1Data, config);
                        console.log('Response:', brandRewardName1.data);
                    }
                    if(element.name === "brandRewardImage2"){
                        const brandRewardImage1 = await axios.put(url+element.id, brandRewardImage1Data, config);
                        console.log('Response:', brandRewardImage1.data);
                    }
                    if(element.name === "brandRewardDesc2"){
                        const brandRewardDesc1 = await axios.put(url+element.id, brandRewardDesc1Data, config);
                        console.log('Response:', brandRewardDesc1.data);
                    }
                    if(element.name === "brandRewardPts2"){
                        const brandRewardPts1 = await axios.put(url+element.id, brandRewardPts1Data, config);
                        console.log('Response:', brandRewardPts1.data);
                    }
                }
            } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            }
          }
          else{
            try {
                const url = 'https://rest.gohighlevel.com/v1/custom-values/';
                const config = {
                    headers: {
                        Authorization: 'Bearer '+brand.integrations.ghl.apiKey // Replace 'your_token_here' with your actual token
                    }
                };
                const matchingOption = filteredOptions.filter(option => filterAudience.includes(option.value));
                console.log('matchingOption',matchingOption)
  
                const brandRewardName1Data = {
                    name: "brandRewardName2",
                    value: ""
                };
                const brandRewardImage1Data = {
                    name: "brandRewardImage2",
                    value: ""
                };
                const brandRewardDesc1Data = {
                    name: "brandRewardDesc2",
                    value: ""
                };
                const brandRewardPts1Data = {
                    name: "brandRewardPts2",
                    value: ""
                };
                const brandRewardCode2Data = {
                    name: "brandRewardCode2",
                    value: ""
                };
                const get = await axios.get(url, config);
                console.log('Response:', get.data);
                if(get.data?.customValues?.length>0)
                for (const element of get.data.customValues) {
                    if(element.name === "brandRewardCode2"){
                        const brandRewardCode2 = await axios.put(url+element.id, brandRewardCode2Data, config);
                        console.log('Response:', brandRewardCode2.data);
                    }
                    if(element.name === "brandRewardName2"){
                        const brandRewardName1 = await axios.put(url+element.id, brandRewardName1Data, config);
                        console.log('Response:', brandRewardName1.data);
                    }
                    if(element.name === "brandRewardImage2"){
                        const brandRewardImage1 = await axios.put(url+element.id, brandRewardImage1Data, config);
                        console.log('Response:', brandRewardImage1.data);
                    }
                    if(element.name === "brandRewardDesc2"){
                        const brandRewardDesc1 = await axios.put(url+element.id, brandRewardDesc1Data, config);
                        console.log('Response:', brandRewardDesc1.data);
                    }
                    if(element.name === "brandRewardPts2"){
                        const brandRewardPts1 = await axios.put(url+element.id, brandRewardPts1Data, config);
                        console.log('Response:', brandRewardPts1.data);
                    }
                }
            } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            }
          }
        }
        if(index === 2 && history.location.state._id === value._id){
          if(formik.values.active === true){
            try {
                const url = 'https://rest.gohighlevel.com/v1/custom-values/';
                const config = {
                    headers: {
                        Authorization: 'Bearer '+brand.integrations.ghl.apiKey // Replace 'your_token_here' with your actual token
                    }
                };
                const matchingOption = filteredOptions.filter(option => filterAudience.includes(option.value));
                console.log('matchingOption',matchingOption)
  
                const brandRewardName1Data = {
                    name: "brandRewardName3",
                    value: matchingOption[0].data.name||"0"
                };
                const brandRewardImage1Data = {
                    name: "brandRewardImage3",
                    value: matchingOption[0].data.image||"0"
                };
                const brandRewardDesc1Data = {
                    name: "brandRewardDesc3",
                    value: matchingOption[0].data.description||"0"
                };
                const brandRewardPts1Data = {
                    name: "brandRewardPts3",
                    value: formik.values.PointCost.toString()||"0"
                };
                const brandRewardCode3Data = {
                    name: "brandRewardCode3",
                    value: selectedpromo.code||''
                };
                const get = await axios.get(url, config);
                console.log('Response:', get.data);
                if(get.data?.customValues?.length>0)
                for (const element of get.data.customValues) {
                    if(element.name === "brandRewardCode3"){
                        const brandRewardCode3 = await axios.put(url+element.id, brandRewardCode3Data, config);
                        console.log('Response:', brandRewardCode3.data);
                    }
                    if(element.name === "brandRewardName3"){
                        const brandRewardName1 = await axios.put(url+element.id, brandRewardName1Data, config);
                        console.log('Response:', brandRewardName1.data);
                    }
                    if(element.name === "brandRewardImage3"){
                        const brandRewardImage1 = await axios.put(url+element.id, brandRewardImage1Data, config);
                        console.log('Response:', brandRewardImage1.data);
                    }
                    if(element.name === "brandRewardDesc3"){
                        const brandRewardDesc1 = await axios.put(url+element.id, brandRewardDesc1Data, config);
                        console.log('Response:', brandRewardDesc1.data);
                    }
                    if(element.name === "brandRewardPts3"){
                        const brandRewardPts1 = await axios.put(url+element.id, brandRewardPts1Data, config);
                        console.log('Response:', brandRewardPts1.data);
                    }
                }
            } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            }
          }
          else{
            try {
                const url = 'https://rest.gohighlevel.com/v1/custom-values/';
                const config = {
                    headers: {
                        Authorization: 'Bearer '+brand.integrations.ghl.apiKey // Replace 'your_token_here' with your actual token
                    }
                };
                const matchingOption = filteredOptions.filter(option => filterAudience.includes(option.value));
                console.log('matchingOption',matchingOption)
  
                const brandRewardName1Data = {
                    name: "brandRewardName3",
                    value: ""
                };
                const brandRewardImage1Data = {
                    name: "brandRewardImage3",
                    value: ""
                };
                const brandRewardDesc1Data = {
                    name: "brandRewardDesc3",
                    value: ""
                };
                const brandRewardPts1Data = {
                    name: "brandRewardPts3",
                    value: ""
                };
                const brandRewardCode3Data = {
                    name: "brandRewardCode3",
                    value: ""
                };
                const get = await axios.get(url, config);
                console.log('Response:', get.data);
                if(get.data?.customValues?.length>0)
                for (const element of get.data.customValues) {
                    if(element.name === "brandRewardCode3"){
                        const brandRewardCode3 = await axios.put(url+element.id, brandRewardCode3Data, config);
                        console.log('Response:', brandRewardCode3.data);
                    }
                    if(element.name === "brandRewardName3"){
                        const brandRewardName1 = await axios.put(url+element.id, brandRewardName1Data, config);
                        console.log('Response:', brandRewardName1.data);
                    }
                    if(element.name === "brandRewardImage3"){
                        const brandRewardImage1 = await axios.put(url+element.id, brandRewardImage1Data, config);
                        console.log('Response:', brandRewardImage1.data);
                    }
                    if(element.name === "brandRewardDesc3"){
                        const brandRewardDesc1 = await axios.put(url+element.id, brandRewardDesc1Data, config);
                        console.log('Response:', brandRewardDesc1.data);
                    }
                    if(element.name === "brandRewardPts3"){
                        const brandRewardPts1 = await axios.put(url+element.id, brandRewardPts1Data, config);
                        console.log('Response:', brandRewardPts1.data);
                    }
                }
            } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            }
          }
        }
      }
      
    }
    
    if(history.location.state.redeemingWayType === enums.RedeemingWays.PROMOTION){
      if(formik.values.active === true){
        try {
  
          const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
          function getDayName(number) {
            if (number >= 1 && number <= 7) {
                return dayNames[number % 7]; // Using modulo operator to handle numbers greater than 7
            } else {
                return "Invalid day number"; // Handling invalid numbers
            }
        }
            const url = 'https://rest.gohighlevel.com/v1/custom-values/';
            const config = {
                headers: {
                    Authorization: 'Bearer '+brand.integrations.ghl.apiKey // Replace 'your_token_here' with your actual token
                }
            };
            const brandPromoData = {
                name: "brandPromo",
                value: formik.values.BonusPoints.toString()||"0"
            };
            const brandPromoTypeData = {
                name: "brandPromoType",
                value: formik.values.DateType.toString()||"0"
            };
  
            if(formik.values.DateType==="Custom Date"){
              const brandPromoWeekData = {
                  name: "brandPromoWeek",
                  value: ""
                  // value: getDayName(formik.values.calenderValue)||"0"
              };
              const brandPromoStartDateData = {
                  name: "brandPromoStartDate",
                  value: new Date(formik.values.dateRange.start_Date).toDateString()||"0"
              };
              const brandPromoEndDateData = {
                  name: "brandPromoEndDate",
                  value: new Date(formik.values.dateRange.end_Date).toDateString()||"0"
              };
              const get = await axios.get(url, config);
              console.log('Response:', get.data);
              if(get.data?.customValues?.length>0)
              for (const element of get.data.customValues) {
                  if(element.name === "brandPromo"){
                      const brandPromo = await axios.put(url+element.id, brandPromoData, config);
                      console.log('Response:', brandPromo.data);
                  }
                  if(element.name === "brandPromoType"){
                      const brandPromoType = await axios.put(url+element.id, brandPromoTypeData, config);
                      console.log('Response:', brandPromoType.data);
                  }
                  if(element.name === "brandPromoWeek"){
                      const brandPromoWeek = await axios.put(url+element.id, brandPromoWeekData, config);
                      console.log('Response:', brandPromoWeek.data);
                  }
                  if(element.name === "brandPromoStartDate"){
                      const brandPromoStartDate = await axios.put(url+element.id, brandPromoStartDateData, config);
                      console.log('Response:', brandPromoStartDate.data);
                  }
                  if(element.name === "brandPromoEndDate"){
                      const brandPromoEndDate = await axios.put(url+element.id, brandPromoEndDateData, config);
                      console.log('Response:', brandPromoEndDate.data);
                  }
              }
  
            }
            
            if(formik.values.DateType==="Week"){
              const brandPromoWeekData = {
                  name: "brandPromoWeek",
                  value: getDayName(formik.values.calenderValue)||"0"
              };
              const brandPromoStartDateData = {
                  name: "brandPromoStartDate",
                  value: ""
              };
              const brandPromoEndDateData = {
                  name: "brandPromoEndDate",
                  value: ""
              };
              const get = await axios.get(url, config);
              console.log('Response:', get.data);
              if(get.data?.customValues?.length>0)
              for (const element of get.data.customValues) {
                  if(element.name === "brandPromo"){
                      const brandPromo = await axios.put(url+element.id, brandPromoData, config);
                      console.log('Response:', brandPromo.data);
                  }
                  if(element.name === "brandPromoType"){
                      const brandPromoType = await axios.put(url+element.id, brandPromoTypeData, config);
                      console.log('Response:', brandPromoType.data);
                  }
                  if(element.name === "brandPromoWeek"){
                      const brandPromoWeek = await axios.put(url+element.id, brandPromoWeekData, config);
                      console.log('Response:', brandPromoWeek.data);
                  }
                  if(element.name === "brandPromoStartDate"){
                      const brandPromoStartDate = await axios.put(url+element.id, brandPromoStartDateData, config);
                      console.log('Response:', brandPromoStartDate.data);
                  }
                  if(element.name === "brandPromoEndDate"){
                      const brandPromoEndDate = await axios.put(url+element.id, brandPromoEndDateData, config);
                      console.log('Response:', brandPromoEndDate.data);
                  }
              }
  
            }
        } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
        }
      }
      else{
        try {
  
          const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
          function getDayName(number) {
            if (number >= 1 && number <= 7) {
                return dayNames[number % 7]; // Using modulo operator to handle numbers greater than 7
            } else {
                return "Invalid day number"; // Handling invalid numbers
            }
        }
            const url = 'https://rest.gohighlevel.com/v1/custom-values/';
            const config = {
                headers: {
                    Authorization: 'Bearer '+brand.integrations.ghl.apiKey // Replace 'your_token_here' with your actual token
                }
            };
            const brandPromoData = {
                name: "brandPromo",
                value: ""
            };
            const brandPromoTypeData = {
                name: "brandPromoType",
                value: ""
            };
  
            if(formik.values.DateType==="Custom Date"){
              const brandPromoWeekData = {
                  name: "brandPromoWeek",
                  value: ""
                  // value: getDayName(formik.values.calenderValue)||"0"
              };
              const brandPromoStartDateData = {
                  name: "brandPromoStartDate",
                  value: ""
              };
              const brandPromoEndDateData = {
                  name: "brandPromoEndDate",
                  value: ""
              };
              const get = await axios.get(url, config);
              console.log('Response:', get.data);
              if(get.data?.customValues?.length>0)
              for (const element of get.data.customValues) {
                  if(element.name === "brandPromo"){
                      const brandPromo = await axios.put(url+element.id, brandPromoData, config);
                      console.log('Response:', brandPromo.data);
                  }
                  if(element.name === "brandPromoType"){
                      const brandPromoType = await axios.put(url+element.id, brandPromoTypeData, config);
                      console.log('Response:', brandPromoType.data);
                  }
                  if(element.name === "brandPromoWeek"){
                      const brandPromoWeek = await axios.put(url+element.id, brandPromoWeekData, config);
                      console.log('Response:', brandPromoWeek.data);
                  }
                  if(element.name === "brandPromoStartDate"){
                      const brandPromoStartDate = await axios.put(url+element.id, brandPromoStartDateData, config);
                      console.log('Response:', brandPromoStartDate.data);
                  }
                  if(element.name === "brandPromoEndDate"){
                      const brandPromoEndDate = await axios.put(url+element.id, brandPromoEndDateData, config);
                      console.log('Response:', brandPromoEndDate.data);
                  }
              }
  
            }
            
            if(formik.values.DateType==="Week"){
              const brandPromoWeekData = {
                  name: "brandPromoWeek",
                  value: ""
              };
              const brandPromoStartDateData = {
                  name: "brandPromoStartDate",
                  value: ""
              };
              const brandPromoEndDateData = {
                  name: "brandPromoEndDate",
                  value: ""
              };
              const get = await axios.get(url, config);
              console.log('Response:', get.data);
              if(get.data?.customValues?.length>0)
              for (const element of get.data.customValues) {
                  if(element.name === "brandPromo"){
                      const brandPromo = await axios.put(url+element.id, brandPromoData, config);
                      console.log('Response:', brandPromo.data);
                  }
                  if(element.name === "brandPromoType"){
                      const brandPromoType = await axios.put(url+element.id, brandPromoTypeData, config);
                      console.log('Response:', brandPromoType.data);
                  }
                  if(element.name === "brandPromoWeek"){
                      const brandPromoWeek = await axios.put(url+element.id, brandPromoWeekData, config);
                      console.log('Response:', brandPromoWeek.data);
                  }
                  if(element.name === "brandPromoStartDate"){
                      const brandPromoStartDate = await axios.put(url+element.id, brandPromoStartDateData, config);
                      console.log('Response:', brandPromoStartDate.data);
                  }
                  if(element.name === "brandPromoEndDate"){
                      const brandPromoEndDate = await axios.put(url+element.id, brandPromoEndDateData, config);
                      console.log('Response:', brandPromoEndDate.data);
                  }
              }
  
            }
        } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
        }
      }
    }
    

    if(history.location.state.redeemingWayType === enums.RedeemingWays.TAG) payload.tag = tags

    if ((history.location.state.redeemingWayType == enums.RedeemingWays.REFERRAL) || (history.location.state.redeemingWayType == enums.RedeemingWays.BIRTHDAY)) {
      payload.description = formik.values.Description
    }
    if (history.location.state.redeemingWayType !== enums.RedeemingWays.REFERRAL && history.location.state.redeemingWayType !== enums.RedeemingWays.TAG) {
      payload.rewardValue = {
        costingPoint: formik.values.PointCost
      }
    }

    if (history.location.state.redeemingWayType === enums.RedeemingWays.REFERRAL) {
      payload.rewardValue = {
        costingPointTo: formik.values.PointCostTo,
        costingPointBy: formik.values.PointCostBy,
        costingPointType: formik.values.PointCostType,
      }
    }
    if (history.location.state.redeemingWayType === enums.RedeemingWays.INACTIVITY) {
      payload.rewardValue = {
        offerPoints: formik.values.offerPoints,
        numberofDays: formik.values.numberofDays,
        tagName: formik.values.tagName
      }
    }
    if (history.location.state.redeemingWayType === enums.RedeemingWays.PROMOTION) {
      payload.rewardValue = {
        bonusPoints: formik.values.BonusPoints,
        dateType: formik.values.DateType,
      }
    }
    if(history.location.state.redeemingWayType === enums.RedeemingWays.PROMOTION && formik.values.DateType == "Week"){
      payload.rewardValue = {
        bonusPoints: formik.values.BonusPoints,
        dateType: formik.values.DateType,
        calenderValue: formik.values.calenderValue,
      }
    }
    if(history.location.state.redeemingWayType === enums.RedeemingWays.PROMOTION && formik.values.DateType == "Custom Date"){
      payload.rewardValue = {
        bonusPoints: formik.values.BonusPoints,
        dateType: formik.values.DateType,
        dateRange: formik.values.dateRange,
      }
    }
    

    if (history.location.state.redeemingWayType === enums.RedeemingWays.PRODUCT) {
      payload.rewardValue.rewardDealId = filterAudience[0];
      payload.rewardValue.productDiscountType = formik.values.DiscountType;
      payload.onetime = formik.values.onetime;
      payload.widget = formik.values.widget;
      if (formik.values.cwCode) payload.rewardValue.cwCode = formik.values.cwCode


      if(promo.length>0){
        const config = {
          method: 'PUT',
          url: `https://api.orderingdashboard.com/v1/restaurants/${brand.integrations.cloudwaitress[0].id}/integration/walletly/promo`,
          headers: {
            Authorization: brand.integrations.cloudwaitress[0].auth,  // API key
            ClientId: '4f8c1385-a157-4a73-8d7f-d3364f8fd651'       // Client ID
          },
          data: {
            promoId: selectedpromo.id,
            walletlyDealId: filterAudience[0]
          }
        };
        console.log('123321 conf data', config.data);
        const response = await axios(config);
        console.log("123321 update POST", response.data);
      }
    } else {
      if (history.location.state.redeemingWayType !== enums.RedeemingWays.BIRTHDAY && history.location.state.redeemingWayType !== enums.RedeemingWays.TAG && history.location.state.redeemingWayType !== enums.RedeemingWays.INACTIVITY) {
        payload.rewardValue.discountValue = formik.values.CustomerGets;

      }
    }
    
    if (formik.values.DiscountType === enums.RedeemingDiscountType.PercentageOff || formik.values.DiscountType === enums.RedeemingDiscountType.AmountOff) payload.rewardValue.discountValue = formik.values.DiscountTypeValue;
    // api
    
    payload.active = formik.values.active
    console.log('Payload', payload);

    

    if (history.location.state.name) {
      const api = await RedeemingWayService.Update({
        payload: { ...payload, _id: history.location.state._id },
        toaster: true,
      });

    } else {
      const api = await RedeemingWayService.Create({ payload, toaster: true });
      // console.log(api);
    }

    const query = { brandId: brand._id, delete: false}
    const redeemingWays = await RedeemingWayService.GetQueriedEarningWays({ query })
    if (redeemingWays?.response){
      localforage.setItem("redeemingWays", redeemingWays.response.data);
    }

    setLoader(false);
    history.goBack();
  };

  const formik = useFormik({
    initialValues: { ...initState },
    onSubmit: handleSubmit,
  });
  {console.log('history.location.state',history.location.state)}


  const handlePriceChange = (e) => {
    if (e.target.value >= 0) {
      formik1.setValues({ ...formik1.values, price: e.target.value });
    }
  };

  // const [test, setTest] = useState(true)
  // const [test1, setTest1] = useState(false)
  // const [test2, setTest2] = useState(false)
  
  // useEffect(() => {
  //   if(history.location.state.name) setTest1(true)
  // }, [])
  
  // useEffect(() => {
  //   layout.setLayout({
  //     placeholder : layout.elements.editTitle ,

  //   })
  // },[layout.elements.editTitle])
  // useEffect(() => {
  //   layout.setLayout({
  //     modalPermission : history.location.state.name ? false: true ,
  //     edit: true,
  //     // title: "Reward " + (filteredOptions.length + 1),
  //     title: "1",
  //     switch: '',
  //     placeholder : layout.elements.editTitle || history?.location?.state?.name || "New Rule",
  //     borderBottom: true,
  //     // backTitles: ["Loyalty Suite", "Redeeming Ways"],
  //     button: <>
      
  //     </>,

  //   });
  //   // setTest(false)
  // }, [layout.elements.editTitle, formik.values, window.location.pathname, filteredOptions]);
 

  useEffect(()=>{
    layout.setLayout({
        borderBottom: false,
        edit        : false,
        backTitles  : [],
        title       : 
                      history.location.state.redeemingWayType === enums.RedeemingWays.INACTIVITY?"Inactivity":
                      history.location.state.redeemingWayType === enums.RedeemingWays.REFERRAL?"Referral":
                      history.location.state.redeemingWayType === enums.RedeemingWays.PROMOTION?"Promotion":
                      history.location.state.redeemingWayType === enums.RedeemingWays.BIRTHDAY?"Birthday":
                      history.location.state.redeemingWayType === enums.RedeemingWays.PRODUCT?"Reward":
                      "Else",
        switch : '',
        button: <></>
    })
},[])


const handleDelete = async ()=>{

  setdeleteLoader(!deleteLoader);
  console.log('brand',brand);
  // const query = { _id: brand._id}

  let redeemingWaysCheck = false;
  let loyaltyBuilderCheck = false;
  // const redeemingWays = await RedeemingWayService.GetQueriedEarningWays({ query })
  // if (redeemingWays?.response) loyaltyBuilderCheck = true
  // else loyaltyBuilderCheck = false

  // console.log('redeemingWays ',redeemingWays)

  console.log('history.location.state',history.location.state);
  // let redIndex = history.location.state.redeemingIndex;
  const payload = {_id: history.location.state._id , delete:true};
  const {response, error} = await RedeemingWayService.Update({ payload });
  if(response){
    const query = { brandId: brand._id, delete: false}
    const redeemingWays = await RedeemingWayService.GetQueriedEarningWays({ query })
    if (redeemingWays?.response){
      localforage.setItem("redeemingWays", redeemingWays.response.data);
    }

    history.push({
      pathname:window.location.pathname.replace('/add','')
     })
    console.log("Updated",response);
  }



  setdeleteLoader(!deleteLoader);
  setDeleteModal(!deleteModal);

}


  // useEffect(() => {
  //   layout.setLayout({
  //     editTitle: !history?.location?.state?.name?"New Rule": history.location.state.name || "Reward " + (filteredOptions.length + 1),
  //   });
  // }, [filteredOptions]);



  useEffect(() => {
    // fulfil = !fulfil
    // setFulfil(!fulfil)
  //   console.log("Winn",window.location.pathname)
  //  console.log("pathhh",history.location.pathname) 
  

    formik.setValues({ ...formik.values, fulfil: !formik.values.fulfil })
    console.log('FIRST',);
  }, [])
  return (
    <div id="AddRedeemingWays" className="middle">
      {console.log('asdasdasdasd',history.location.state)}
      {console.log('selectedpromo',selectedpromo)}
      {console.log('layout.elements.editTitle',layout.elements)}
      <div className="w-55 mt_40">
      {history.location.state.redeemingWayType === enums.RedeemingWays.INACTIVITY && <div className="mb_12 d-flex gap-16 position-relative" >
        <img 
          className={formik.values.active?"imgcard":"imgcard"} onClick={()=>formik.setValues({...formik.values, active: !formik.values.active})} 
          src={PngIcons.NewIABlock} width={68} height={68}/>
        <div>
            <div className="Heading22M color-neutral100">{"Inactivity"}
              {<img style={{left: '43px'}} className="ml_8 position-absolute" src={formik.values.active?PngIcons.tick:PngIcons.tickG} width={16} height={16}/>}
              </div>
            <div className="Body14R color-neutral60">{"While the text you provided doesn't directly relate to inactivity, we can adapt the concept to fit this context"}</div>
        </div>
      </div>}
      {history.location.state.redeemingWayType === enums.RedeemingWays.REFERRAL && <div className="mb_12 d-flex gap-16 position-relative" >
        <img className={formik.values.active?"imgcard":"imgcard"} onClick={()=>formik.setValues({...formik.values, active: !formik.values.active})}  src={PngIcons.NewRBlock} width={68} height={68}/>
        <div>
            <div className="Heading22M color-neutral100">{"Referral"}{<img style={{left: '43px'}} className="ml_8 position-absolute" src={formik.values.active?PngIcons.tick:PngIcons.tickG} width={16} height={16}/>}</div>
            <div className="Body14R color-neutral60">{"Refer a friend and you both earn 10 points when your friend places an order on our website. Use the link below to refer people."}</div>
        </div>
      </div>}
      {history.location.state.redeemingWayType === enums.RedeemingWays.PROMOTION && <div className="mb_12 d-flex gap-16 position-relative" >
        <img className={formik.values.active?"imgcard":"imgcard"} onClick={()=>formik.setValues({...formik.values, active: !formik.values.active})}  src={PngIcons.newPBlock} width={68} height={68}/>
        <div>
            <div className="Heading22M color-neutral100">{"Promotion"}{<img style={{left: '43px'}} className="ml_8 position-absolute" src={formik.values.active?PngIcons.tick:PngIcons.tickG} width={16} height={16}/>}</div>
            <div className="Body14R color-neutral60">{"Boost loyalty with multiplied points on selected dates"}</div>
        </div>
      </div>}
      {history.location.state.redeemingWayType === enums.RedeemingWays.BIRTHDAY && <div className="mb_12 d-flex gap-16 position-relative" >
        <img className={formik.values.active?"imgcard":"imgcard"} onClick={()=>formik.setValues({...formik.values, active: !formik.values.active})}  src={PngIcons.newBD} width={68} height={68}/>
        <div>
            <div className="Heading22M color-neutral100">{"Birthday"}{<img style={{left: '43px'}} className="ml_8 position-absolute" src={formik.values.active?PngIcons.tick:PngIcons.tickG} width={16} height={16}/>}</div>
            <div className="Body14R color-neutral60">{"Delight customers with special points on their special day"}</div>
        </div>
      </div>}
      {history.location.state.redeemingWayType === enums.RedeemingWays.PRODUCT && <div className="mb_12 d-flex gap-16 position-relative" >
        <img className={formik.values.active?"imgcard":"imgcard"} onClick={()=>formik.setValues({...formik.values, active: !formik.values.active})}  src={PngIcons.newRewardBlock} width={68} height={68}/>
        <div>
            <div className="Heading22M color-neutral100">{"Reward"}{<img style={{left: '43px'}} className="ml_8 position-absolute" src={formik.values.active?PngIcons.tick:PngIcons.tickG} width={16} height={16}/>}</div>
            <div className="Body14R color-neutral60">{"Offering free product as a reward to your customers is a great way to both give back to your most loyal program members, promote new or featured products and control your inventory."}</div>
        </div>
      </div>}



        {history.location.state.redeemingWayType !== enums.RedeemingWays.INACTIVITY&&
        history.location.state.redeemingWayType !== enums.RedeemingWays.REFERRAL&&
        history.location.state.redeemingWayType !== enums.RedeemingWays.PROMOTION&&
        history.location.state.redeemingWayType !== enums.RedeemingWays.BIRTHDAY&&
        history.location.state.redeemingWayType !== enums.RedeemingWays.PRODUCT&&
        <div className="Heading22M color-neutral100 mt_40 mb_6">
          {history.location.state.redeemingWayType ===
            enums.RedeemingWays.AMOUNT
            ? "Amount Discount"
            : history.location.state.redeemingWayType ===
              enums.RedeemingWays.PERCENT
              ? "Percent Off"
              : history.location.state.redeemingWayType ===
                enums.RedeemingWays.REFERRAL ? "Referral"
                : history.location.state.redeemingWayType ===
                  enums.RedeemingWays.BIRTHDAY ? "Birthday" 
                : history.location.state.redeemingWayType ===
                  enums.RedeemingWays.INACTIVITY ? "" 
                : history.location.state.redeemingWayType ===
                  enums.RedeemingWays.TAG ? "Add Tags" :
                  "Product Offer"}
        </div>}
        {console.log('fomik.values ', formik.values)}
        {history.location.state.redeemingWayType !== enums.RedeemingWays.INACTIVITY&&
        history.location.state.redeemingWayType !== enums.RedeemingWays.REFERRAL&&
        history.location.state.redeemingWayType !== enums.RedeemingWays.PROMOTION&&
        history.location.state.redeemingWayType !== enums.RedeemingWays.BIRTHDAY&&
        history.location.state.redeemingWayType !== enums.RedeemingWays.PRODUCT&&
        <div className="Body14R color-neutral60">
          {
          history.location.state.redeemingWayType === enums.RedeemingWays.PRODUCT || history.location.state.redeemingWayType === enums.RedeemingWays.INACTIVITY
            ? ""
            : history.location.state.redeemingWayType === enums.RedeemingWays.TAG ? 'Offering free product as a reward to your customers is a great way to both give back to your most loyal program members, promote new or featured products and control your inventory.'
            : history.location.state.redeemingWayType === enums.RedeemingWays.BIRTHDAY ?

              <CustomTextArea
                value={formik.values.Description}
                onChange={formik.handleChange}
                label="Description"
                name="Description"
                rows="2"
              />

              : history.location.state.redeemingWayType === enums.RedeemingWays.REFERRAL ?
                <>
                  <CustomTextArea
                    value={formik.values.Description}
                    onChange={formik.handleChange}
                    label="Description"
                    rows="2"
                    name="Description"
                  />
                </> :
                history.location.state.redeemingWayType === enums.RedeemingWays.PROMOTION ? <>Offering free product as a reward to your customers is a great way to both give back to your most loyal program members, promote new or featured products and control your inventory.</> :
                  <>"In place an order earning way, customer will earn points every time they place any order.</>}
        </div>}
        <div className="bar mt_12 mb_26"></div>
        {history.location.state.redeemingWayType !== enums.RedeemingWays.TAG ? <div className="Heading16M color-neutral100 mb_16">{history.location.state.redeemingWayType === enums.RedeemingWays.INACTIVITY?"":""}</div> : <div className="Heading16M color-neutral100 mb_16">Tags</div>}
        {history.location.state.redeemingWayType === enums.RedeemingWays.TAG && 
        
        tags.length>0&&tags.map((value, index) =>(
        <div className="d-flex justify-content-center gap-32 mt_24 align-items-center">
            <div className='underline color-neutral90 B14M w-100'>
            <CustomSelect
              label={"Tag Type"}
              onChange={(e) => {
                tags[index].type = e.target.value
                settags([...tags])
              }}
              options={[
                {
                  name: "Visits",
                  value: 'Visits'
                },
                {
                  name: "Points",
                  value: 'Points'
                },
                // {
                //   name: "Inactivity",
                //   value: 'Inactivity'
                // },
              ]}
              value={tags[index].type}
            />
          </div>
          <div className="w-100">
            <CustomTextFieldValue
                label = {tags[index].type === 'Inactivity'? "Number Of Inactivity" : "Number Of "+tags[index].type}
                type="number"
                value={tags[index].number}
                onChange={(e)=>{
                  console.log(index,'e.target.value',e.target.value);
                  tags[index].number = parseInt(e.target.value)
                  settags([...tags])
                }}
              />
          </div>
          <div className="w-100">
            <CustomTextFieldValue
                label = {"Tag Name"}
                onChange={(e)=>{
                  tags[index].name = e.target.value
                  settags([...tags])
                }}
                value={tags[index].name}
              />
          </div>
          <div className="cp" onClick={()=>{
            if(tags.length>1){
              console.log('index',index);
              tags.splice(index, 1);
              settags([...tags])
              console.log('tags',tags);
            }
        }}><SvgIcons.CrossIcon/></div>
        </div>))

        }
        {history.location.state.redeemingWayType === enums.RedeemingWays.TAG && <div className="mt_30" onClick={()=>{
          tags.push({type: 'Visits', number: 0, name: 'tag'})
          settags([...tags])
        }}>
          <div className="d-flex justify-content-center gap-12 pt_12 pb_12 cp hoverclass">
            <div><SvgIcons.PlusIcon color={ColorSchemeCode.primary50}/></div>
            <div className="color-primary50 B14M">Add More Tag</div>
          </div>
        </div>}

        {
          history.location.state.redeemingWayType === enums.RedeemingWays.PROMOTION &&
          <div className={`d-flex ${!formik.values.active&&'disabled'} gap-10`}>
            <div className="w-100">
              <CustomSelect
                label={"Point Multiplier"}
                options={[
                  { name: enums.rulebuilder_points.DOUBLE_THE_POINTS_X_2 },
                  { name: enums.rulebuilder_points.TRIPLE_THE_POINTS_X_3 },
                  { name: enums.rulebuilder_points.QUADRUPLE_THE_POINTS_X_4 },
                ]}
                value={formik.values.BonusPoints}
                onChange={(e) => formik.setValues({ ...formik.values, BonusPoints: e.target.value })}
              /></div>


            <div className="w-100">
              <CustomSelect
                label={"Select Type"}
                options={[
                  { name: "Custom Date", month: '', date: '' },
                  { name: "Week", month: '', date: '' },
                ]}
                value={formik.values.DateType}
                onChange={(e) => formik.setValues({ ...formik.values, DateType: e.target.value, CalenderValue: '' , dateRange:'' })}
              />
            </div>

            <div className="w-100">
              {formik.values.DateType === 'Custom Date' ?
                <>
                  <div className='underline color-neutral90 B14M w-100'>
                    <div className=" mainfocusactive w-100 wpx-230 cp h-fit-content B14M color-neutral80 underline rpInput">
                      <DateRangePicker
                      // '3/1/2014'
                        initialSettings={formik.values.dateRange.start_Date && formik.values.dateRange.end_Date &&
                          { startDate: `${new Date(formik.values.dateRange.start_Date).getMonth() + 1}`
                         + '/' +  
                         `${new Date(formik.values.dateRange.start_Date).getDate()}` 
                          + '/' + 
                          `${new Date(formik.values.dateRange.start_Date).getFullYear()}` , endDate: `${new Date(formik.values.dateRange.end_Date).getMonth() + 1}` + '/' +  `${new Date(formik.values.dateRange.end_Date).getDate()}`  + '/' + `${new Date(formik.values.dateRange.end_Date).getFullYear()}` }}
                        onCallback={(start, end, label) => {
                          formik.setValues({ ...formik.values, dateRange: { 'start_Date': new Date(start._d).valueOf(), 'end_Date': new Date(end._d).valueOf() } })
                        }
                        
                        }
                        >
                        <input type="text" className="cp form-control color-neutral80 underline shadow-none border-none w-100 " style={{ fontSize: '14px', fontWeight: '500' }} />
                      </DateRangePicker>
                    </div>

                  </div>
                </>
                : formik.values.DateType === 'Week' ? <>
                  <div className='underline color-neutral90 B14M w-100'>
                    <CustomSelect
                      // style={{ backgroundColor: 'inherit' , padding : '12.5px 14px' , border: '0.5px solid gray' }}
                      // className={' focusactive  cp w-100' }
                      label={"Days"}

                      onChange={(e) => formik.setValues({ ...formik.values, calenderValue: e.target.value })}
                      options={[
                        {
                          name: "Sunday",
                          value: '0'
                        },
                        {
                          name: "Monday",
                          value: '1'
                        },
                        {
                          name: "Tuesday",
                          value: '2'
                        }, {
                          name: "Wednesday",
                          value: '3'
                        }, {
                          "name": 'Thursday',
                          value: '4'
                        }, {
                          name: "Friday",
                          value: '5'
                        }, {
                          name: "Saturday",
                          value: '6'
                        }, 
                      ]}
                      // onChange={(e) => { finalstate[history.location.state.index].state[index].week = e.target.value; setFinalstate([...finalstate]) }}
                      value={+formik.values.calenderValue}
                    />
                  </div>
                </> : console.log('none')
              }
            </div>
          </div>
        }
        
        <div className="">

          {history.location.state.redeemingWayType !==
            enums.RedeemingWays.REFERRAL && 
            history.location.state.redeemingWayType !== enums.RedeemingWays.PROMOTION && 
            history.location.state.redeemingWayType !== enums.RedeemingWays.TAG && 
            history.location.state.redeemingWayType !== enums.RedeemingWays.INACTIVITY && 
            <div className="w-100">
              <div className={`d-flex ${!formik.values.active&&`disabled`} gap-10 w-100`}>
                <div className="w-100">
                  <CustomTextField
                    label = {"Points"}
                    type  = "number"
                    // left={32}
                    name="PointCost"
                    className={history.location.state.redeemingWayType === enums.RedeemingWays.BIRTHDAY && "w-50"}
                    value={formik.values.PointCost}
                    onChange={formik.handleChange}
                    inputProps={{ onFocus: formik.handleBlur }}
                    error={formik.touched.PointCost && formik.errors.PointCost}
                    helperText={
                      formik.touched.PointCost && formik.errors.PointCost
                        ? formik.errors.PointCost
                        : ""
                    }
                  />
                </div>
                {history.location.state.redeemingWayType !== enums.RedeemingWays.BIRTHDAY && <div className="w-100">
                      <div className="d-flex align-items-center gap-8">
                        <div className="position-relative Body14R color-neutral60 w-100">
                          {console.log('e.target.value.code f',formik.values.cwCode)}
                        {promo.length>0?
                        <FormControl variant="outlined" fullWidth>
                        {/* <InputLabel id="cwCode-label">Give CW Code</InputLabel> */}
                          <Select
                            labelId="cwCode-label"
                            name="cwCode"
                            label="Give CW Code"
                            style={{ height: '44px' }}
                            variant="outlined"
                            className="B16R"
                            onChange={(e) => {
                              const selectedPromo = promo.find(item => item.code === e.target.value);
                              console.log('Selected promo:', selectedPromo);
                              
                              // Set the value in formik as the code, and save the selected promo object separately
                              formik.setValues({ ...formik.values, cwCode: selectedPromo.code });
                              setselectedpromo(selectedPromo);
                            }}
                            value={
                              formik.values.cwCode // Formik needs the selected `code` for display
                                ? formik.values.cwCode
                                : "" // Empty string if no value is selected
                            }
                            error={formik.touched.cwCode && !!formik.errors.cwCode}
                            helperText={
                              formik.touched.cwCode && formik.errors.cwCode
                                ? formik.errors.cwCode
                                : ""
                            }
                          >
                            {promo.length > 0 &&
                              promo.map((value, index) => (
                                <MenuItem key={index} value={value.code}>
                                  {value.code}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                        :
                          <CustomTextField
                            name="cwCode"
                            label="Give CW Code"
                            value={formik.values.cwCode}
                            onChange={formik.handleChange}
                            inputProps={{ onFocus: formik.handleBlur }}
                            error={formik.touched.cwCode && formik.errors.cwCode}
                            helperText={
                              formik.touched.cwCode && formik.errors.cwCode
                                ? formik.errors.cwCode
                                : ""
                            }
                          />
                        }
                        {promo.length<=0&&<div className="position-absolute top-11 right-7">
                          <CopyText content={formik.values.cwCode} hideText={true} />
                        </div>}
                        </div>
                      </div>
                </div>}
              </div>
              {history.location.state.redeemingWayType !== enums.RedeemingWays.BIRTHDAY &&<div className={`childmlhandle ${!formik.values.active&&`disabled`} mt_10`}>
                <CustomCheckBox
                  value={formik.values.onetime}
                  onChange={(e) => formik.setValues({ ...formik.values, onetime: !formik.values.onetime })}
                  label={<div className="Body14R pl_15">This offer can only be used once, as it is valid for a single use.</div>}
                />
              </div>}
              {history.location.state.redeemingWayType !== enums.RedeemingWays.BIRTHDAY &&<div className={`childmlhandle ${!formik.values.active&&`disabled`} mb_20`}>
                <CustomCheckBox
                  value={formik.values.widget}
                  onChange={(e) => formik.setValues({ ...formik.values, widget: !formik.values.widget })}
                  label={<div className="Body14R pl_15">Do not show in widget.</div>}
                />
              </div>}
            </div>
            }
          {history.location.state.redeemingWayType ===
            enums.RedeemingWays.REFERRAL && (<div className={`row ${!formik.values.active&&`disabled`}`}>
              <div className="col-4">

                <CustomTextField
                  right="32px"
                  // id="ff"
                  label={"Incentive for singup"}
                  type="number"
                  // icon={"points"}
                  placeholder={"Points"}
                  position={"end"}
                  name="PointCostTo"
                  value={formik.values.PointCostTo}
                  onChange={formik.handleChange}
                  inputProps={{ onFocus: formik.handleBlur }}
                  error={formik.touched.PointCostTo && formik.errors.PointCostTo}
                  helperText={
                    formik.touched.PointCostTo && formik.errors.PointCostTo
                      ? formik.errors.PointCostTo
                      : ""
                  }
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end">
                  //       {"points"}
                  //     </InputAdornment>
                  //   ),
                  // }}
                />

              </div>
              <div className="col-4">
                <CustomTextField
                  right="32px"
                  label={"Incentive to invite for singup"}
                  type="number"
                  // icon={"points"}
                  placeholder={"Points"}
                  position={"end"}
                  name="PointCostBy"
                  value={formik.values.PointCostBy}
                  onChange={formik.handleChange}
                  inputProps={{ onFocus: formik.handleBlur }}   
                  error={formik.touched.PointCostBy && formik.errors.PointCostBy}
                  helperText={
                    formik.touched.PointCostBy && formik.errors.PointCostBy
                      ? formik.errors.PointCostBy
                      : ""
                  }
                />
              </div>
              <div className="col-4">
                <CustomSelect
                  label={"Reward Type"}
                  name="PointCostType"

                  options={
                    [
                      {
                        name: 'Sign Up',
                        value: 'signup'
                      },
                      {
                        name: 'Activity',
                        value: 'activity'
                      }
                    ]
                  }
                  value={formik.values.PointCostType}
                  onChange={formik.handleChange}
                  error={formik.errors.PointCostType}
                  helperText={formik.errors.PointCostType}
                />
              </div>
            </div>
            )}
          {
            history.location.state.redeemingWayType !== enums.RedeemingWays.PRODUCT &&
            history.location.state.redeemingWayType !== enums.RedeemingWays.BIRTHDAY &&
            history.location.state.redeemingWayType !== enums.RedeemingWays.PROMOTION &&
            history.location.state.redeemingWayType !== enums.RedeemingWays.TAG &&

            history.location.state.redeemingWayType !== enums.RedeemingWays.REFERRAL && 
            history.location.state.redeemingWayType !== enums.RedeemingWays.INACTIVITY && 
            (
              <CustomTextField

                label={
                  history.location.state.redeemingWayType ===
                    enums.RedeemingWays.AMOUNT
                    ? "Customer gets"
                    : "Discount value"
                }
                type="number"
                icon={
                  history.location.state.redeemingWayType ===
                    enums.RedeemingWays.AMOUNT
                    ? "$"
                    : "% off"
                }
                position={
                  history.location.state.redeemingWayType ===
                    enums.RedeemingWays.AMOUNT
                    ? "start"
                    : "end"
                }
                paddingLeft={history.location.state.redeemingWayType ===
                  enums.RedeemingWays.AMOUNT
                  ? "30px"
                  : ""
                }
                paddingRight={formik.values.DiscountType !=
                  enums.RedeemingDiscountType.AmountOff
                  ? ""
                  : "30px"
                }
                name="CustomerGets"
                value={formik.values.CustomerGets}
                onChange={formik.handleChange}
                inputProps={{ onFocus: formik.handleBlur }}
                error={formik.touched.CustomerGets && formik.errors.CustomerGets}
                helperText={
                  formik.touched.CustomerGets && formik.errors.CustomerGets
                    ? formik.errors.CustomerGets
                    : ""
                }
              />
            )}
        </div>

        {/* {history.location.state.redeemingWayType ===
          enums.RedeemingWays.PRODUCT && (
            <div style={{ minWidth: "41.55%", width: "fit-content" }}>
              <CustomDropDown
                showCheckBox  = {false}
                setFilterData = {setFilterAudience}
                search        = {true}
                allFilterShow = {false}
                label         = {"Product"}
                options       = {filteredOptions}
                className     = {"mb_16"}
                classNameB    = {"w-30"}
              />
            </div>
          )} */}

          {console.log('filteredOptions',filteredOptions)}
          {console.log('filterAudience',filterAudience)}
        
        {console.log('history.location.state.redeemingWayType',history.location.state.redeemingWayType)}
        {history.location.state.redeemingWayType ===
          enums.RedeemingWays.PRODUCT && (
            filteredOptions && filteredOptions.length > 0 &&
            <>
            <div className="bar"></div>
            <div className={`mt_20 mb_10 ${!formik.values.active&&`disabled`} Link16M`} style={{color:"#141519"}}>Choose Product</div>
            <div className={!formik.values.active&&`disabled`} style={{ 
              display: 'grid', 
              // gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', 
              gridTemplateColumns: 'repeat(2, 1fr)', 
              gap: '16px' 
            }}>
              {filteredOptions.map((value, index) => (
                <Card key={index} style={{
                  backgroundColor: filterAudience[0] === value.value && '#F2F6FF',
                  border: filterAudience[0] === value.value && '2px solid #2960EC'
                }} sx={{ height: 'fit-content' }} onClick={() => {
                  // Handle click
                  setFilterAudience([value.value])
                }}>
                  {console.log('value',value)}
                  <CardActionArea style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <CardContent style={{ width: '100%' }}>
                      {filterAudience[0] === value.value?
                        <div className="d-flex gap-6">
                        <Typography gutterBottom style={{fontSize: '15px', color: '#141519', fontWeight: 500, fontFamily: 'Inter'}} variant="h5" component="div" sx={{fontWeight:'bold'}}>
                        {utils.capitalize(value.data.name)}
                        </Typography>
                        <img src={PngIcons.tick} width={16} height={16}/>
                      </div>
                        :
                        <Typography gutterBottom variant="h5" style={{fontSize: '15px', color: '#141519', fontWeight: 500, fontFamily: 'Inter'}} component="div" sx={{ fontWeight: 'bold' }}>
                        {utils.capitalize(value.data.name)}
                      </Typography>
                      }
                      <Typography className="ellipsis-multiple-lines" style={{fontSize: '12px', color: '#2E3039', fontWeight: 400, fontFamily: 'Inter'}} variant="h6" color="text.secondary" sx={{
                        // overflow: 'hidden',
                        // display: '-webkit-box',
                        // WebkitBoxOrient: 'vertical',
                        // WebkitLineClamp: 2, // Limits to 2 lines
                        // textOverflow: 'ellipsis',
                      }}>
                        {utils.capitalize(value.data.description)}
                      </Typography>
                      <IconButton className="p_4" sty aria-label="edit" size="large" style={{position: 'absolute', bottom: '15px'}} onClick={(e)=>{
                        e.stopPropagation()
                        // formik1.setValues({...initState1})
                        formik1.setValues({
                          ...formik1.values,
                          _id: value.data._id,
                          brandLogo: value.data.image,
                          name: value.data.name,
                          name1: value.data.name,
                          price: value.data.discountedPrice,
                          description: value.data.description,
                          edit: true,
                        });
                        // if (index1 == index) setIndex();
                        setModal2(true);
                      }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          fill="none"
                          // {...props}
                        >
                          <path
                            fill="#686C7C"
                            d="M19.554 16.54a3.495 3.495 0 0 1-3.492 3.492H7.638a3.495 3.495 0 0 1-3.492-3.492V8.119a3.495 3.495 0 0 1 3.492-3.492h3.043V3.626H7.638a4.5 4.5 0 0 0-4.492 4.493v8.421a4.5 4.5 0 0 0 4.492 4.493h8.422a4.5 4.5 0 0 0 4.493-4.493v-3.042h-1.001v3.042z"
                          />
                          <path
                            fill="#686C7C"
                            d="M19.974 4.692a2.362 2.362 0 0 0-3.345 0l-8.282 8.282c-.34.34-.561.781-.628 1.258l-.382 2.673a.376.376 0 0 0 .37.428q.027 0 .054-.004l2.673-.382a2.22 2.22 0 0 0 1.258-.628l8.282-8.282a2.365 2.365 0 0 0 0-3.345m-10.62 8.491 7.009-7.01 2.127 2.13-7.007 7.01zm.96 2.913-2.035.29.29-2.035c.028-.192.096-.377.199-.538l2.08 2.081a1.3 1.3 0 0 1-.535.202m9.052-8.669-.268.268-2.127-2.13.267-.267c.284-.284.663-.44 1.065-.44.403 0 .781.156 1.065.44a1.51 1.51 0 0 1-.002 2.13"
                          />
                        </svg>
                        {/* <ModeEditTwoToneIcon fontSize="large" /> */}
                        {/* <svg class="feather feather-edit" fill="none" height="24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"/></svg> */}
                      </IconButton>
                    </CardContent>
                    <CardMedia
                      component="img"
                      height="140"
                      style={{ padding: 0, width: '140px' }}
                      image={value.data.image}
                      alt={value.data.name}
                    />
                  </CardActionArea>
                </Card>
              ))}
              <div onClick={()=>{
              formik1.setValues({ ...initState1, price: "0", edit: false });
              setModal2(true);
            }
              } className='w-100  trigger1' style={{ alignContent: 'center', color:'#2960EC', minHeight: '140px' }} varient='tertiary'>
              <SvgIcons.Add color={'#2960EC'}/><br/>
              Add New Product
            </div>
            </div>
            </>
            
          )}





        {history.location.state.redeemingWayType ===
          enums.RedeemingWays.INACTIVITY &&<div className={`d-flex ${!formik.values.active&&`disabled`} gap-16`}>
          <div
            className="d-flex space-between mt_24 "
            style={{ width: "41.55%", alignItems: "center" }}
          >
            <CustomSelect
                label={"Number of days"}
                name="numberofDays"
                value={formik.values.numberofDays}
                onChange={formik.handleChange}
                options={[
                  {
                    name: "30",
                    value: 30
                  },
                  {
                    name: "60",
                    value: 60
                  },
                  {
                    name: "90",
                    value: 90
                  },
                  // {
                  //   name: "Inactivity",
                  //   value: 'Inactivity'
                  // },
                ]}
              />
            {/* <CustomTextField
              right="32px"
              label={"Number of days"}
              type="number"
              placeholder={"Number of days"}
              name="numberofDays"
              value={formik.values.numberofDays}
              onChange={formik.handleChange}
              inputProps={{ onFocus: formik.handleBlur }}
              error={formik.touched.numberofDays && formik.errors.numberofDays}
              helperText={
                formik.touched.numberofDays && formik.errors.numberofDays
                  ? formik.errors.numberofDays
                  : ""
              }
            /> */}
          </div>
          <div
            className="d-flex space-between mt_24 "
            style={{ width: "41.55%", alignItems: "center" }}
          >
            <CustomTextField
              right="32px"
              label={"Points"}
              type="number"
              placeholder={"Points"}
              name="offerPoints"
              value={formik.values.offerPoints}
              onChange={formik.handleChange}
              inputProps={{ onFocus: formik.handleBlur }}
              error={formik.touched.offerPoints && formik.errors.offerPoints}
              helperText={
                formik.touched.offerPoints && formik.errors.offerPoints
                  ? formik.errors.offerPoints
                  : ""
              }
            />
          </div>
          <div className=" mt_24">
            <CustomTextFieldValue
                label = {"Tag Name"}
                name="tagName"
                value={formik.values.tagName}
                onChange={formik.handleChange}
                inputProps={{ onFocus: formik.handleBlur }}
                error={formik.touched.tagName && formik.errors.tagName}
                helperText={
                  formik.touched.tagName && formik.errors.tagName
                    ? formik.errors.tagName
                    : ""
                }
                // onChange={(e)=>{
                //   tags[index].name = e.target.value
                //   settags([...tags])
                // }}
                // value={tags[index].name}
              />
          </div>
        </div>}
        {
          console.log('history.location.state.redeemingWayType',history.location.state.redeemingWayType)
        }
        {history.location.state.redeemingWayType ===
          enums.RedeemingWays.PRODUCT && (
            <>
              <div className="w-100">
                <RadioGroup
                  name="DiscountType"
                  value={formik.values.DiscountType}
                  onChange={formik.handleChange}
                  onFocus={formik.handleBlur}
                >
                  {brand?.integrations?.cloudwaitress ?
                    <></>
                    :
                    <div className="radiotop pt_16 pb_16">
                      <CustomRadio
                        value={enums.RedeemingDiscountType.GiveFreeProduct}
                        label={
                          <div>
                            <div className="Body14R color-neutral100">
                              Give Product
                            </div>
                            <div className="Body14R color-neutral60">
                              Customer will get the product free of cost.
                            </div>
                          </div>
                        }
                      />
                    </div>
                  }
                </RadioGroup>

                {(formik.values.DiscountType ===
                  enums.RedeemingDiscountType.AmountOff ||
                  formik.values.DiscountType ===
                  enums.RedeemingDiscountType.PercentageOff) && (
                    <div
                      className="d-flex space-between mt_24 "
                      style={{ width: "41.55%", alignItems: "center" }}
                    >
                      <CustomTextField
                        type="number"
                        classNameA="w-100"
                        top={"14"}
                        icon={
                          formik.values.DiscountType ===
                            enums.RedeemingDiscountType.AmountOff
                            ? <div className="" style={{ marginTop: "-1px" }}>{brand.currency.code}</div>
                            : "% off"
                        }
                        position={
                          formik.values.DiscountType ===
                            enums.RedeemingDiscountType.AmountOff
                            ? "start"
                            : "end"
                        }
                        paddingLeft={formik.values.DiscountType ===
                          enums.RedeemingDiscountType.AmountOff 
                          ? "22px"
                          : ""
                        }
                        paddingRight={formik.values.DiscountType ===
                          enums.RedeemingDiscountType.AmountOff
                          ? ""
                          : "38px"
                        }
                        name="DiscountTypeValue"
                        value={formik.values.DiscountTypeValue}
                        onChange={formik.handleChange}
                        inputProps={{ onFocus: formik.handleBlur }}
                        error={
                          formik.touched.DiscountTypeValue &&
                          formik.errors.DiscountTypeValue
                        }
                        helperText={
                          formik.touched.DiscountTypeValue &&
                            formik.errors.DiscountTypeValue
                            ? formik.errors.DiscountTypeValue
                            : ""
                        }
                      />
                    </div>
                  )}

              </div>
              <div className="bar mt_20 mb_16"></div>
              
            </>
          )}

        {
          history.location.state.redeemingWayType !==
          enums.RedeemingWays.PRODUCT &&
          <div className="bar mt_20 mb_16"></div>

        }
         {
                         <CustomsModal
                         open={deleteModal}
                         minWidth={"600px"}
                         component={
                           <DeleteModal
                             icon={deleteLoader}
                             title="Reward"
                             onCancel={()=>{setDeleteModal(false)}}
                             onDelete={handleDelete}
                           />
                         }
                         onClose={()=>{setDeleteModal(false)}}
                       />
                    }

    <div className={`d-flex gap-10 red-last align-items-center space-between mb_20`}>
    
      <div className={`d-flex gap-10 red-last align-items-center `}>
        <CustomButton
          varient={"primary"}
          disabled={loader || (history.location.state.redeemingWayType === enums.RedeemingWays.PROMOTION && formik.values.DateType == "Week" && !formik.values.calenderValue) || (history.location.state.redeemingWayType === enums.RedeemingWays.PROMOTION && formik.values.DateType == 'Custom Date' && !formik.values.dateRange)}
          icon={loader && <CircularProgress size={20} color={"inherit"} />}
          btntext={history.location.state.name ? "Save Changes" : "Create"}
          className=""
          onClick={handleSubmit}
        />
        <CustomButton varient = {"secondary1"} btntext = {"Cancel"} onClick={()=>{history.goBack()}} />

      </div>

        {/* {history.location.state.redeemingWayType === enums.RedeemingWays.PRODUCT&&history.location.state._id&&<CustomButton
       varient="warningSecondary1"
       btntext={"Delete"}
       className=""
        onClick={()=>{
          setDeleteModal(!deleteModal);
        }}
       />} */}

</div>

      </div>


      <CustomModal
        open={modal2}
        onClose={() => setModal2(false)}
        maxWidth={550}
        minWidth={500}
        title={
          <div className="d-flex space-between">
            <div className="Heading22M color-neutral100">
              {formik.values.edit ? "Edit Reward" : "Add Reward"}
            </div>
            <div
              className="cp"
              onClick={() => {
                setModal2(false);
                formik.setValues({ ...initState });
              }}
            >
              <SvgIcons.CrossIcon />
            </div>
          </div>
        }
        component={
          <Modal2Component
            initState={initState1}
            setDeleteModal={setDeleteModal1}
            state={state1}
            handleSubmit={handleSubmit1}
            formik={formik1}
            setModal2={setModal2}
            setImageModal={setImageModal}
            imageModal={imageModal}
            handlePriceChange={handlePriceChange}
            filteredOptions={filteredOptions}
          />
        }
      />

      <CustomsModal
        open={deleteModal1}
        minWidth={"600px"}
        component={
          <DeleteModal
            icon={deleteLoader1}
            title="Reward"
            onCancel={() => setDeleteModal1(false)}
            onDelete={handleDelete1}
          />
        }
        onClose={() => setDeleteModal1(false)}
      />
    </div>
  );
}

const Modal2Component = ({
  setDeleteModal,
  setModal2,
  filteredOptions,
  formik,
  setImageModal,
  imageModal,
  handlePriceChange,
  handleSubmit,
  state,
  initState,
}) => {
  const brand = useContext(BrandContext);
  return (
    <div>
      {/* {console.log('child 2',state.result.length)} */}
      <div id="AddInventory2">
        <div className="modalComponent">
          <div className="mt_16">
            <CustomTextField
              label="Reward Name"
              className="w-100"
              name="name"
              placeholder="Enter name"
              value={formik.values.name}
              onChange={formik.handleChange}
              inputProps={{ onFocus: formik.handleBlur }}
              error={formik.touched.name && formik.errors.name}
              helperText={
                formik.touched.name && formik.errors.name
                  ? formik.errors.name
                  : ""
              }
            />
          </div>

          <div
            className="col-12 pl_0 pr_0 z-index-20 "
            style={{ height: "165px" }}
          >
            {console.log('formik.values.brandLogoFile',formik.values.brandLogoFile)}
            {console.log('formik.values.brandLogo',formik.values.brandLogo)}
            <LogoUploader
              src={
                formik.values.brandLogoFile
                  ? URL.createObjectURL(formik.values.brandLogoFile)
                  : 
                  formik.values.brandLogo
              }
              label="Brand Cover"
              // size         = 's'
              displayLabel="Deal Image"
              hint={
                <div>
                  <div className="Link12R color-neutral60">
                    File requirements JPG, PNG, WebP upto 10 MB.ss
                  </div>
                  <div className="Link12R color-neutral60">
                    Minimum pixels required: 550 x 440.
                  </div>
                </div>
              }
              className="mt_24 mb_24 pl_0"
              error={formik.values.brandLogoFile && formik.errors.brandLogoFile}
              btntext="Upload Deal Image"
              btnName="Upload"
              htmlFor={"logoUpload"}
              onChange={(e) => {
                setImageModal({
                  ...imageModal,
                  logo: { ...imageModal.logo, open: true, imageEvent: e },
                });
              }}
            />
          </div>
          <div className="error">{formik.errors.brandLogoFile}</div>
          {
            console.log('brand.currency.symbol',brand)
          }

          {/* <div className="mt_16" style={{ position: 'relative' }}>
            <CustomTextField
              label="Reward Price (Optional)"
              // paddingLeft = "55px"

              className="w-100"
              name="price"
              type="number"
              left={10}
              icon={formik.values.price ? <div>{brand.location?.code }</div> : <></>} //style={{position:'absolute' , top:0}}
              position="start"
              // paddingLeft={30}
              placeholder="Enter price"
              value={formik.values.price}
              inputProps={{ onFocus: formik.handleBlur, style: { marginLeft: '30px' } }}
              onChange={(e) => handlePriceChange(e)}
              error={formik.touched.price && formik.errors.price}
              helperText={
                formik.touched.price && formik.errors.price
                  ? formik.errors.price
                  : ""
              }
            />
          </div> */}
          <div className="mt_16 mb_24">
            <CustomTextArea
              label="Description (Optional)"
              className="w-100"
              name="description"
              placeholder="Enter short description about the reward"
              value={formik.values.description}
              onChange={formik.handleChange}
              inputProps={{ onFocus: formik.handleBlur }}
              error={formik.touched.description && formik.errors.description}
              helperText={
                formik.touched.description && formik.errors.description
                  ? formik.errors.description
                  : ""
              }
            />
          </div>
        </div>

        <CustomModal
          open={imageModal.logo.open}
          onClose={() =>
            setImageModal({
              ...imageModal,
              logo: { ...imageModal.logo, open: false },
            })
          }
          minWidth="470px"
          minHeight="470px"
          padding="0px"
          component={
            <ImageModalV2
              imageModal={imageModal}
              setImageModal={setImageModal}
              image={"logo"}
              type={"logo"}
              formik={formik}
            />
          }
        />
      </div>
      <div className="bar mb_20 mt_32"></div>
      <div className="w-100 d-flex justify-content-right">
        {(formik.values.edit)&&filteredOptions?.length>1&& <CustomButton
          varient="dangerSecondary"
          btntext={"Delete"}
          onClick={() => {
            // formik.setValues({
            //   ...formik.values,
            //   _id: value._id,
            // });
            setDeleteModal(true);
          }}
        />}
        <CustomButton
          varient="secondary"
          btntext={"Cancel"}
          className={"ml_10"}
          onClick={() => {
            setModal2(false);
            setTimeout(() => {
              formik.setValues({ ...initState });
            }, 2000);
          }}
        />
        <CustomButton
          disabled={state.loader || !formik.isValid}
          onClick={handleSubmit}
          icon={
            state.loader && <CircularProgress size={20} color={"inherit"} />
          }
          className={"ml_10"}
          btntext={formik.values.edit ? "Update" : "Create"}
        />
      </div>
    </div>
  );
};

export default AddRedeemingWays;